









































































































































































































	import { Component, Vue } from 'vue-property-decorator'
	import Response from '@/components/Response.vue'
	import Endpoint from '@/components/Endpoint.vue'
	import Request from '@/components/Request.vue'
	import Authorization from '@/components/Authorization.vue'
	import '@/assets/themes/prism-darcula.css'
	import Prism from "prismjs";

	@Component({
		components: {
			Response,
			Request,
			Endpoint,
			Authorization,
		},
	})
	export default class UpdateLotLicensePlate extends Vue { 
    private version = "v2"
    private requestNotes: string | null = null
    
private hasAccountRoleNote = false;
private hasAccountUserRoleNote = false;


		mounted() {
			Prism.highlightAll();
      this.run()
		}

    run(){
      // Run additional code upon mounting
      
    }
	}
